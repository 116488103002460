<template>
  <BlogPostContent
    v-once
    :post="post"
    :breadcrumbs="breadcrumbs"
    :fix-locale="fixLocale"
  />
</template>

<script setup lang="ts">
import { GET_WP_POST, GET_WP_RELATED_POSTS } from '@/utils/queries';
import { fixContent, fixDataUrl, fixImageUrl, getGraph } from '@/helpers/fixes';
import { useMainStore } from '@/store';
import type { Post, PostCard, PostFromResponse } from '@/types/common';
import { parseMeta } from '@/utils/header';

interface PostDetailsFromResponse {
  title: string;
  content: string;
  date: string;
  slug: string;
  categories: {
    nodes: {
      name: string;
      slug: string;
      translations: {
        slug: string;
      }[];
      parent?: {
        node: {
          name: string;
          slug: string;
          translations: {
            slug: string;
          }[];
        };
      };
    }[];
  };
  tags: {
    nodes: {
      name: string;
      slug: string;
    }[];
  };
  featuredImage?: {
    node: {
      altText: string;
      sourceUrl: string;
    };
  };
  translations: {
    slug: string;
    uri: string;
  }[];
  seo: {
    title: string;
    description: string;
    fullHead: string;
    schema: {
      raw: string;
    };
  };
  nextPost?: {
    title: string;
    slug: string;
    categories: {
      nodes: {
        slug: string;
      }[];
    };
  };
  previousPost?: {
    title: string;
    slug: string;
    categories: {
      nodes: {
        slug: string;
      }[];
    };
  };
}

interface BannerFromResponse {
  title: string;
  fields: {
    cta: string;
    ctaUrl: string;
    description: string;
    image: {
      altText: string;
      sourceUrl: string;
    };
  };
}

interface RecentPostFromResponse {
  title: string;
  slug: string;
  categories: {
    nodes: {
      slug: string;
    }[];
  };
}

interface PostResponse {
  post: PostDetailsFromResponse;
  sidebarBanners: {
    nodes: BannerFromResponse[];
  };
  recentPosts: {
    nodes: RecentPostFromResponse[];
  };
}

const client = useWpClient();

const {
  public: { cloudflareImageProcessing: cfUrl }
} = useRuntimeConfig();

const { locale, t, setLocale } = useI18n();

const localePath = useLocalePath();

const store = useMainStore();

const props = defineProps<{
  slug: string;
  fixLocale: string;
}>();

if (props.fixLocale && locale.value !== props.fixLocale) {
  setLocale(props.fixLocale);
}

async function fetch(): Promise<PostResponse> {
  const gqlResponse = await client.query<{
    posts: {
      nodes: [PostDetailsFromResponse];
    };
    sidebarBanners: {
      nodes: BannerFromResponse[];
    };
    recentPosts: {
      nodes: RecentPostFromResponse[];
    };
  }>({
    query: GET_WP_POST,
    variables: {
      language: locale.value === 'us' ? 'en' : locale.value,
      slug: props.slug
    }
  });

  const post = gqlResponse.data.posts?.nodes?.[0];

  if (!post) {
    showError({
      statusCode: 404,
      message: t('common.pageNotFound'),
      fatal: true
    });

    throw new Error(t('common.pageNotFound'));
  }

  // const parentCategorySlug =
  //   post.categories.nodes[0]?.parent?.node?.slug || 'posts';
  const categorySlug = post.categories.nodes[0]?.slug;
  const slug = post.slug;
  // const currentPath = `/${parentCategorySlug}/${categorySlug}/${slug}`;
  const currentPath = `/${categorySlug}/${slug}`;

  const translationCategorySlug =
    post.categories.nodes[0]?.translations[0]?.slug;
  // const translationParentCategorySlug =
  //   post.categories.nodes[0]?.parent?.node?.translations[0]?.slug || 'posts';
  const translationSlug = post.translations[0]?.slug;

  if (translationCategorySlug && translationSlug) {
    const translationPath =
      // `/${translationParentCategorySlug}` +
      `/${translationCategorySlug}` + `/${translationSlug}`;
    store.setTranslationPath(currentPath, translationPath);
  }

  return {
    post,
    sidebarBanners: gqlResponse.data.sidebarBanners,
    recentPosts: gqlResponse.data.recentPosts
  };
}

const { data: asyncResponse, error: fetchError } =
  await useAsyncData<PostResponse | null>(async () => {
    if (process.client) {
      return null;
    }
    return await fetch();
  });

if (fetchError?.value && fetchError.value instanceof Error) {
  console.error(fetchError.value);

  showError({
    statusCode: 404,
    message: t('common.pageNotFound'),
    fatal: true
  });

  throw new Error(t('common.pageNotFound'));
}

const post = computed<Post>(() => {
  const response = asyncResponse.value?.post;

  if (!response) {
    return null;
  }

  const image = response.featuredImage?.node?.sourceUrl
    ? fixImageUrl(response.featuredImage?.node?.sourceUrl)
    : null;

  const imageAlt = response.featuredImage?.node?.altText;

  const meta = parseMeta(fixDataUrl(response.seo?.fullHead || ''));

  const seo = response.seo
    ? {
        title: response.seo.title,
        description: response.seo.description,
        schema: response.seo.schema?.raw,
        meta
      }
    : null;

  const categories =
    response.categories?.nodes.map(node => {
      return {
        name: node.name,
        path: localePath(
          `/${t('routes.blog')}` +
            // (node.parent?.node?.slug ? `/${node.parent.node.slug}` : '') +
            `/${node.slug}`
        ),
        slug: node.slug,
        parent: node.parent?.node?.slug
          ? {
              name: node.parent.node.name,
              path: localePath(
                `/${t('routes.blog')}` + `/${node.parent.node.slug}`
              ),
              slug: node.parent.node.slug
            }
          : null
      };
    }) || [];

  const tags =
    response.tags?.nodes.map(node => {
      return {
        name: node.name,
        path: localePath(`/${t('routes.blog')}/tag/${node.slug}`)
      };
    }) || [];

  const styleTagRegex = /<style[^>]*>[\s\S]*?<\/style>/gi;
  const vcTagRegex = /\[\/?vc_[^\]]+\]/gi;
  const content = fixContent(
    response.content
      .replace(styleTagRegex, '')
      .replace(vcTagRegex, ' ')
      .replace(/\s+/g, ' '),
    cfUrl
  );

  const translation = {
    slug: response.translations[0]?.slug,
    parentCategorySlug:
      response.categories.nodes[0]?.parent?.node?.translations[0]?.slug ||
      'posts',
    categorySlug: response.categories.nodes[0]?.translations[0]?.slug
  };

  return {
    title: response.title,
    content,
    date: new Date(response.date),
    slug: response.slug,
    image,
    imageAlt,
    seo,
    categories,
    tags,
    translation
  };
});

useSeoMeta({
  title: () => post.value?.seo?.title || post.value?.title,
  description: () => post.value?.seo?.description || ''
});

useSchemaOrg(() => {
  let schemaJson;
  try {
    schemaJson = post.value?.seo?.schema
      ? JSON.parse(fixDataUrl(post.value.seo.schema))
      : null;
  } catch {}

  const graph = getGraph(schemaJson);

  return (Array.isArray(graph) ? graph : [graph]).filter(
    g => g?.['@type'] !== 'BreadcrumbList'
  );
});

useHead(() => ({
  meta: post.value?.seo?.meta || []
}));

const { link } = useCanonicalLink((t, linkLocale) => {
  if (!post.value) {
    return;
  }
  if (locale.value === linkLocale) {
    // const parentCategory = post.value?.categories[0]?.parent?.slug || 'posts';

    return localePath(
      `/${t('routes.blog')}` +
        // `/${parentCategory}` +
        `/${post.value?.categories[0]?.slug}` +
        `/${post.value?.slug}`,
      linkLocale
    );
  } else if (
    post.value?.translation?.categorySlug &&
    // post.value?.translation?.parentCategorySlug &&
    post.value?.translation?.slug
  ) {
    return localePath(
      `/${t('routes.blog')}` +
        // `/${post.value?.translation?.parentCategorySlug}` +
        `/${post.value?.translation?.categorySlug}` +
        `/${post.value?.translation?.slug}`,
      linkLocale
    );
  }
});

const host = useHost();

const breadcrumbs = computed(() => [
  {
    name: t('seo.home'),
    item: host + localePath('/')
  },
  {
    name: t('menu.blog'),
    item: host + localePath(`/${t('routes.blog')}`)
  },
  ...(post.value?.categories.flatMap(category => {
    if (!category.name) {
      return [];
    }
    if (category.parent?.name) {
      return [
        {
          name: category.parent.name,
          item: host + localePath(category.parent.path)
        },
        {
          name: category.name,
          item: host + localePath(category.path)
        }
      ];
    }
    return [
      {
        name: category.name,
        item: host + localePath(category.path)
      }
    ];
  }) || []),
  {
    name: post.value?.title,
    item: localePath(
      `/${t('routes.blog')}` +
        `/${post.value?.categories[0]?.slug}` +
        `/${post.value?.slug}`
    ),
    interactuable: false
  }
]);

useSchemaOrg([
  ...useCommonSchemaOrg(link),
  defineBreadcrumb({
    '@id': link + '#breadcrumb',
    itemListElement: breadcrumbs.value.map(({ name, item }) => ({ name, item }))
  })
]);
</script>
