<template>
  <BlogResultsContent
    v-once
    :request-state="searchAsync.data.value?.state"
    :found="searchAsync.data.value?.found"
    :items="searchAsync.data.value?.items"
    :page="page"
    :category="searchAsync.data.value?.category"
    :search-query="searchQuery"
    :breadcrumbs="breadcrumbs"
    :fix-locale="fixLocale"
  />
</template>

<script setup lang="ts">
import {
  GET_WP_POSTS_BY_CATEGORY,
  GET_WP_POSTS_BY_SEARCH
} from '@/utils/queries';
import { fixDataUrl } from '@/helpers/fixes';
import type { MetaItem, PostCard, PostFromResponse } from '@/types/common';
import { parseMeta } from '@/utils/header';

interface CategoryFromResponse {
  name: string;
  slug: string;
  translations: {
    name: string;
    slug: string;
  }[];
  parent?: {
    node: {
      name: string;
      slug: string;
      translations: {
        name: string;
        slug: string;
      }[];
    };
  };
  seo?: {
    title: string;
    description: string;
    fullHead: string;
  };
}

interface Category {
  name: string;
  slug: string;
  parent?: {
    name: string;
    slug: string;
  };
}

interface CategorySeo {
  meta: MetaItem[];
  title: string;
  description: string;
}

interface Data {
  state?: 'initializing' | 'loading' | 'error' | 'success';
  found: number;
  items: PostCard[];
  category?: Category;
  alternateCategory?: Category;
  seo?: CategorySeo;
}

const props = defineProps<{
  slug: string;
  fixLocale: string;
}>();

const page = defineModel<number>('page', {
  default: 1
});

const { locale, t, setLocale } = useI18n();

if (props.fixLocale && locale.value !== props.fixLocale) {
  setLocale(props.fixLocale);
}

function tl(key: string, locale: string = 'us') {
  return t(key, null, { locale });
}

const localePath = useLocalePath();

const client = useWpClient();

const POSTS_PER_PAGE = 12;

const searchQuery = (() => {
  const categoryOrSearch = props.slug.replace(/\/$/, '');

  if (!categoryOrSearch) {
    return '';
  }

  const blogSearchEn = tl('routes.blogSearch', 'us');
  const blogSearchEs = tl('routes.blogSearch', 'mx');

  const isSearchEn = categoryOrSearch.endsWith(`-${blogSearchEn}`);
  const isSearchEs = categoryOrSearch.endsWith(`-${blogSearchEs}`);

  if (isSearchEn) {
    return categoryOrSearch.replace(`-${blogSearchEn}`, '');
  } else if (isSearchEs) {
    return categoryOrSearch.replace(`-${blogSearchEs}`, '');
  } else {
    return '';
  }
})();

async function fetch(): Promise<Data> {
  try {
    const categorySlug = props.slug;

    const gqlResponse = await client.query<{
      posts: {
        nodes: PostFromResponse[];
        pageInfo: {
          offsetPagination: {
            total: number;
          };
        };
      };
      category?: CategoryFromResponse;
    }>({
      query: searchQuery ? GET_WP_POSTS_BY_SEARCH : GET_WP_POSTS_BY_CATEGORY,
      variables: {
        ...(searchQuery
          ? { search: searchQuery }
          : { categorySlug, categoryId: categorySlug }),
        perPage: POSTS_PER_PAGE,
        offset: (page.value - 1) * POSTS_PER_PAGE,
        language: locale.value === 'us' ? 'en' : locale.value
      },
      fetchPolicy: 'no-cache'
    });

    const items = gqlResponse.data.posts.nodes.map(node => {
      const category = node.categories.nodes[0];

      // const parentCategory = category?.parent?.node || { slug: 'posts' };

      const categoryLink = localePath(
        `/${t('routes.blog')}` +
          // `/${parentCategory?.slug}` +
          `/${category?.slug}`
      );

      const to = localePath(
        `/${t('routes.blog')}` +
          // `/${parentCategory?.slug}` +
          `/${category?.slug}` +
          `/${node.slug}`
      );

      return {
        title: node.title,
        excerpt: node.excerpt,
        to,
        image: node.featuredImage?.node?.sourceUrl,
        imageAlt: node.featuredImage?.node?.altText,
        category: category?.name,
        categoryLink
      };
    });

    const found =
      gqlResponse.data.posts.pageInfo?.offsetPagination?.total || items.length;

    const meta = parseMeta(
      fixDataUrl(gqlResponse.data.category?.seo?.fullHead || '')
    );

    const seo = {
      meta,
      title: gqlResponse.data.category?.seo?.title,
      description: gqlResponse.data.category?.seo?.description
    };

    const parent = gqlResponse.data.category?.parent?.node;

    const category = {
      name: gqlResponse.data.category?.name,
      slug: gqlResponse.data.category?.slug,
      parent: parent
        ? {
            name: parent.name,
            slug: parent.slug
          }
        : undefined
    };

    if (categorySlug && !searchQuery && !category.slug) {
      // navigateTo(localePath(`/${t('routes.blog')}`), {
      //   replace: true,
      //   redirectCode: 301
      // });

      showError({
        statusCode: 404,
        message: t('common.pageNotFound'),
        fatal: true
      });

      throw new Error(t('common.pageNotFound'));
    }

    const alternateParent =
      gqlResponse.data.category?.parent?.node?.translations[0];

    const alternateCategory = {
      name: gqlResponse.data.category?.translations[0]?.name,
      slug: gqlResponse.data.category?.translations[0]?.slug,
      parent: alternateParent
        ? {
            name: alternateParent.name,
            slug: alternateParent.slug
          }
        : undefined
    };

    return {
      state: 'success',
      items,
      found,
      category,
      alternateCategory,
      seo
    };
  } catch (error) {
    console.error(error);
    return {
      state: 'error',
      items: [],
      found: 0,
      category: {
        name: '',
        slug: ''
      },
      seo: null
    };
  }
}

const searchAsync = await useAsyncData('blog-results', () =>
  Object.freeze(fetch())
);

const seo = searchAsync.data.value?.seo;

useSeoMeta({
  title: () => seo?.title || searchAsync.data.value?.category?.name,
  description: () => seo?.description || t('blog.description')
});

useHead(() => ({
  meta: seo?.meta || []
}));

const { link } = useCanonicalLink((t, linkLocale) => {
  const categorySlug = searchAsync.data.value?.category?.slug;
  // const categoryParentSlug = searchAsync.data.value?.category?.parent?.slug;

  const alternateCategorySlug = searchAsync.data.value?.alternateCategory?.slug;
  // const alternateCategoryParentSlug =
  //   searchAsync.data.value?.alternateCategory?.parent?.slug;

  if (categorySlug) {
    if (locale.value === linkLocale) {
      return (
        `/${t('routes.blog')}` +
        // (categoryParentSlug ? `/${categoryParentSlug}` : '') +
        `/${categorySlug}` +
        (page.value > 1 ? `/${page.value}-${t('paramsKeys.page')}` : '')
      );
    } else {
      return (
        `/${t('routes.blog')}` +
        // (alternateCategoryParentSlug ? `/${alternateCategoryParentSlug}` : '') +
        `/${alternateCategorySlug}` +
        (page.value > 1 ? `/${page.value}-${t('paramsKeys.page')}` : '')
      );
    }
  } else if (searchQuery) {
    return (
      `/${t('routes.blog')}` +
      `/${searchQuery}-${t('routes.blogSearch')}` +
      (page.value > 1 ? `/${page.value}-${t('paramsKeys.page')}` : '')
    );
  }
});

const host = useHost();

const breadcrumbs = (() => {
  const category = searchAsync.data.value?.category;
  return [
    {
      name: t('seo.home'),
      item: host + localePath('/')
    },
    {
      name: t('menu.blog'),
      item: host + localePath(`/${t('routes.blog')}`)
    },
    ...(category?.parent?.name
      ? [
          {
            name: category?.parent?.name || '',
            item:
              host +
              localePath(`/${t('routes.blog')}` + `/${category?.parent?.slug}`)
          }
        ]
      : []),
    ...(category?.name
      ? [
          {
            name: category.name,
            item:
              `/${t('routes.blog')}` +
              (category?.parent?.slug ? `/${category.parent.slug}` : '') +
              `/${category?.slug}` +
              (page.value > 1 ? `/${page.value}-${t('paramsKeys.page')}` : ''),
            interactuable: false
          }
        ]
      : []),
    ...(searchQuery
      ? [
          {
            name: t('common.searchResults'),
            item:
              `/${t('routes.blog')}` +
              `/${searchQuery}-${t('routes.blogSearch')}` +
              (page.value > 1 ? `/${page.value}-${t('paramsKeys.page')}` : ''),
            interactuable: false
          }
        ]
      : [])
  ];
})();

useSchemaOrg([
  ...useCommonSchemaOrg(link),
  defineBreadcrumb({
    '@id': link + '#breadcrumb',
    itemListElement: breadcrumbs.map(({ name, item }) => ({ name, item }))
  })
]);
</script>
